<template>
  <el-card class="box-card">
    <!-- <div slot="header" class="clearfix">
      <h3 class="inBlock">门店邀约排行</h3>
    </div> -->
     <div slot="header" class="clearfix">
			<h3>
				<el-tooltip class="item" effect="dark" content="返回上一页" placement="bottom">
				    <i @click="goBack" style="margin-right: 40px; cursor: pointer;" class="el-icon-arrow-left"></i>
				</el-tooltip>
				投诉问题
			</h3>
		</div>
         <el-form ref="form" :model="sizeForm" label-width="85px">
            <el-form-item label="投诉问题">
                <el-input
                placeholder="请输入投诉问题"
                size="mini"
                v-model="sizeForm.title"
                ></el-input>
            </el-form-item>
            <!-- <el-form-item label="添加时间">
                <el-input
                placeholder="请输入添加时间"
                size="mini"
                v-model="form.user_phone"
                ></el-input>
            </el-form-item> -->
        </el-form>
        <el-button size="mini" type="primary" @click="addInfo">新增投诉问题</el-button>
        <el-button size="mini" type="primary" @click="search">筛选</el-button>
        <!-- 表格内容 -->
         <el-table
            ref="multipleTable"
            :data="tableData"
            border
            tooltip-effect="dark"
            style="width: 100%; margin-top:15px">
            <el-table-column label="投诉问题" prop="title">
            </el-table-column>
            <el-table-column
                label="添加时间"
                prop="createtime">
                <template slot-scope="scope">
                    {{scope.row.createtime| datefilter}}
                </template>
			</el-table-column>
            <el-table-column
                label="编辑时间"
                prop="updatetime">
                <template slot-scope="scope">
                    {{scope.row.updatetime| datefilter}}
                </template>
			</el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="danger" size="mini" @click="del(scope.row.id)">删除</el-button>
                    <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页控制 -->
        <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="sizeForm.page"
        @current-change="setPage">
        </el-pagination>
        <el-dialog :title="title" :visible.sync="addDialogVisible" width="40%">
            <el-form
                :model="addFrom"
                ref="liveFrom"
                label-width="100px"
            >
                <el-form-item label="投诉问题">
                    <el-input v-model="addFrom.title"></el-input>
                </el-form-item>
            </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="warning" @click="addDialogVisible = false">关闭</el-button>
            <el-button type="primary" @click="submitProSele">立即创建</el-button>
        </span>
    </el-dialog>
    </el-card>
</template>
<script>
import { storeComplaintQuestion, saveComplaintQuestion, statusComplaintQuestion } from '@/api/api.js'
import { filenameFormat } from "@/utils/index";
import { getSystemsettings } from "../../../api/getSystemsetting.js";
export default {
    components:{},
    name: 'commodityRanking',
    data(){
        return{
            sizeForm: {
                limit: 10, //每页显示条数
                page: 1,
               status: '', //投诉状态：1.启用，2.禁用，10.删除
                title: '', //投诉问题
            },
            form: {

            },
            addFrom: {
                id: '', //	问题id（编辑时用）
                title: '' //投诉问题
            },
            title: '新增投诉问题',
            addDialogVisible: false,
            total: 0, //总条数
            dateTime: '', //时间区间
            tableData:[], //列表数据
            handleSelect: [], //多选数据
            idArry: '' , //多选id组
            exportShow: false,
            exportType: '',
            imgurl: ''
        }
    },
     mounted() {
        getSystemsettings.then((res) => {
        this.imgurl = res;
        });
    },
    created(){
        this.getlist()
    },
    methods:{
        goBack() {
            console.log('go back');
            this.$router.back(-1)
        },
        // 获取评论列表
        async getlist() {
            // this.form.live_id = this.$route.query.liveid;
            const { data } = await storeComplaintQuestion(this.sizeForm);
            if (data.code != 200) return this.$message.error(data.data);
            this.tableData = data.data.data;
            this.total = data.data.total;
        },
        // 绑定新门店
        bindingShop(id){
            this.$refs['bindingShopModel'].modelShow(id)
        },
        // 子组件添加成功回调
        submitSuccess(e){
            this.$message({ type: 'success', message: e })
            this.getlist()
        },
        // 取消导出
        exportShows() {
            this.exportShow = false;
        },
        // 导出订单
        // exportShop() {
        //     this.exportShow = true;
        // },
        // 新增
        addInfo(){
             this.addFrom.id = ''
            this.addFrom.title = ''
            this.addDialogVisible = true
        },
        // 搜索
        search(){
            this.getlist()
        },
        // 编辑
        edit(row){
            this.addDialogVisible = true
            this.addFrom.id = row.id //	问题id（编辑时用）
            this.addFrom.title = row.title//投诉问题
             console.log(row)
        },
        // 删除
        async del(id) {
            let res = {
                id: id,
                status: 10
            }
            // const { data } = await delrole(res);
            this.$confirm("请确定是否删除投诉问题?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                const { data } = await statusComplaintQuestion(res);
                if (data.code != 200) return this.$message.error(data.data);
                console.log(data);
                this.getlist();
                this.$message({
                    type: "success",
                    message: "删除成功!",
                });
                })
                .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消删除",
                });
                });
            },
        // 新增编辑提交
        async submitProSele(){
            const { data } = await saveComplaintQuestion(this.addFrom);
            if (data.code != 200) return this.$message.error(data.data);
            this.addFrom.id = ''
            this.addFrom.title = ''
             this.getlist()
            this.addDialogVisible = false
        },
        // 确认导出
        async exportShop() {
            if(!this.sizeForm.luckdraws_id){
                this.$message.error("请查询id筛选相应会议奖项进行导出!");
                return
            }
            let { data, types, request } = await luckdrawsaddressOutexcel(this.sizeForm, "blob");
            let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
            console.log('blob=======',blob);
            let objectUrl = URL.createObjectURL(blob); //生成下载链接
            let a = document.createElement("a"); //创建a标签用于文件下载
            a.href = objectUrl; //赋值下载路径
            a.download = filenameFormat(
              request.getResponseHeader("content-disposition")
            ); //下载的文件名称（非必填）
            // console.log('filename====',filename)
            document.body.appendChild(a); //插入DOM树
            a.click(); //点击a标签触发
            document.body.removeChild(a); //删除a标签
                // this.form.export = '';
        },
        // 查询
        onSearch(){
            this.sizeForm.page = 1
            this.getlist()
        },
        // 列表多选
        handleSelectionChange(e){
            this.handleSelect = e
            let id = []
            this.handleSelect.forEach(item => {
                id.push(item.id)
            })
            this.idArry = id.join(',')
            
        },
        // 列表多选绑定
        toggleSelectionHidden(){
            this.$refs['bindingShopModel'].modelShow(this.idArry)
        },
        // 分页控制
        setPage(e){
            this.sizeForm.page = e
            this.getlist()
        },
    }
}
</script>
<style lang="less" scope>
.app{
    padding: 30px;
}
/deep/ .el-table {
    .cell {
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
} 

</style>
<style  lang="less">

 .listimg {
    width: 50px;
  }
    img {
      width: 100%;
    }
</style>